import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, Link, navigate, useStaticQuery } from "gatsby"

const Footer = ({ lang, translates, thin = false }) => {
    const [openLanguage, setOpenLanguage] = useState(false)

    const query = useStaticQuery(graphql`query {
        menu: allMarkdownRemark (filter: { frontmatter: { name: {eq: "menu"} } }) {
            edges {
                node {
                    frontmatter {
                        lang
                        product {
                            name
                        }
                        identity {
                            name
                            description
                            url
                        }
                        customer_hub {
                            name
                            url
                        }
                        signature {
                            name
                            description
                            url
                        }
                        rpa {
                            name
                            description
                            url
                        }
                        auth {
                            name
                            description
                            url
                        }
                        communication {
                            name
                            description
                            url
                        }
                        anti_fraud {
                            name
                            description
                            url
                        }
                        about_tecalis {
                            name
                        }
                        about_us {
                            name
                            url
                            description
                        }
                        news {
                            name
                            url
                            description
                        }
                        contact {
                            name
                            url
                            description
                        }
                        blog {
                            name
                            url
                        }
                        partners {
                            name
                            url
                        }
                        account {
                            name
                        }
                        request_demo {
                            name
                            url
                        }
                        knowledge_center {
                            name
                            url
                        }
                        solutions {
                            name
                        }
                        use_cases {
                            name
                        }
                        industries {
                            name
                        }
                        areas {
                            name
                        }
                        resources {
                            name
                            blog
                            kc
                            featured
                        }
                        webinars {
                            url
                        }
                        white_papers {
                            url
                        }
                        case_study {
                            url
                        }
                        privacy {
                            name
                            url
                        }
                        terms_use {
                            name
                            url
                        }
                        terms_conditions {
                            name
                            url
                        }
                        public_financing {
                            name
                            url
                        }
                        time_stamp_policies {
                            name
                            url
                        }
                    }
                }
            }
        }
        industries: allStrapiIndustry (filter: {show_in_menu: {eq: true}}) {
            edges {
                node {
                    name
                    title
                    tag
                    url
                    orderId
                    icon_new {
                        localFile{
                            publicURL
                        }
                    }
                    industries_category {
                        id
                        url
                    }
                    show_in_menu
                    locale
                }
            }
        }
        menuData: allStrapi2022Menu {
            edges {
                node {
                    locale
                    products_news {
                        title
                        description
                        link
                        type
                        image {
                            name
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 378
                                        height: 208
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    case_use_list {
                        use_cases {
                            title
                            description
                            url
                        }
                    }
                    about_us_news {
                        title
                        description
                        link
                        type
                        image {
                            name
                            localFile {
                                publicURL
                                childImageSharp {
                                    gatsbyImageData(
                                        width: 378
                                        height: 208
                                        placeholder: BLURRED
                                        formats: [WEBP]
                                    )
                                }
                            }
                        }
                    }
                    featured_resources
                }
            }
        }
        genericLandings: allStrapiGenericLandingPage (
            filter: {strapiId: {in: [47, 46]}}
        ) {
            edges{
                node {
                    locale
                    url
                }
            }
        }
        useCases: allStrapiCasoDeUso
        {
            edges {
                node {
                    footer_name
                    tag
                    url
                    locale
                }
            }
        }
        sectors: allStrapiSector
        {
            edges {
                node {
                    locale
                    url
                    tag
                }
            }
        }
    }`)
    const baseUrl = lang === "en" ? "" : `/${lang}`
    const sectorUrl = lang === "en" ? "/industries" : "/es/industrias"
    const useCasesUrl = lang === "en" ? "/use-cases" : "/es/casos-uso"
    const menu = query.menu.edges.filter(item => item.node.frontmatter.lang === lang)[0].node.frontmatter
    const industries = query.industries.edges.filter(item => item.node.locale === lang && (item.node.industries_category.id === 1 || item.node.industries_category.id === 4)).sort((a, b) => a.node.orderId - b.node.orderId)
    const sectors = query.sectors.edges.filter(item => item.node.locale === lang)
    const areas = query.industries.edges.filter(item => item.node.locale === lang && (item.node.industries_category.id === 2 || item.node.industries_category.id === 3)).sort((a, b) => a.node.orderId - b.node.orderId)
    const genericLanding = query.genericLandings.edges.filter(item => item.node.locale === lang)[0].node
    const menuData = query.menuData.edges.filter(item => item.node.locale === lang)[0].node
    const useCases = query.useCases.edges.filter(item => item.node.locale === lang)

    const changeLang = (value) => {
        if (translates && translates[value]) {
            return navigate(translates[value])
        }
    }

    const onFocusOut = () => {
        setTimeout(() => setOpenLanguage(false), 500)
    }

    return <footer className={`footer ${thin && `footer--thin`}`}>
        <div className="container">
            {!thin && <div className="footer__nav">
                <div className="footer__nav__logo">
                    <div className="logo__group">
                        <div className="logo__logo">
                            <StaticImage src={"../../../new/resources/tecalis-white.svg"} alt="Tecalis" />
                        </div>

                        <div className="logo__language">
                            <div className={`dropdown dropdown--ghost ${openLanguage ? "dropdown--open" : ""}`} onBlur={onFocusOut}>
                                <button className="dropdown__trigger input" onClick={() => setOpenLanguage(!openLanguage)}>
                                    <span>{lang === "es" ? "Español" : "English"}</span>
                                    <i className="icon-chevron-down"></i>
                                </button>
                                <div className="dropdown__menu">
                                    <div className={`dropdown__menu__item ${lang === "es" ? "dropdown__menu__item--selected" : ""}`} onClick={() => changeLang("es")}>Español</div>
                                    <div className={`dropdown__menu__item ${lang === "en" ? "dropdown__menu__item--selected" : ""}`} onClick={() => changeLang("en")}>English</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="logo__logos-cert">
                        <a href="https://www.csqa.it/en-us/certificazioni/information-technology/eidas-identita-digitale" target="_blank" rel="noreferrer">
                            <StaticImage src={"../../../new/resources/footer/CSQA.png"} alt="CSQA" />
                        </a>
                        <Link to={`${baseUrl}/${genericLanding.url}`}>
                            {lang === 'es' && <StaticImage src={`../../../new/resources/footer/eidas_es.png`} alt="eIDAS" />}
                            {lang === 'en' && <StaticImage src={`../../../new/resources/footer/eidas_en.png`} alt="eIDAS" />}
                        </Link>
                    </div>

                </div>
                <div className="footer__nav__links">
                    <div className="links__title">{menu.product.name}</div>
                    <ul>
                        <li>
                            <Link to={menu.customer_hub.url}>{menu.customer_hub.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.signature.url}>{menu.signature.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.communication.url}>{menu.communication.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.auth.url}>{menu.auth.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.anti_fraud.url}>{menu.anti_fraud.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.identity.url}>{menu.identity.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.rpa.url}>{menu.rpa.name}</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer__nav__links">
                    <div className="links__title">{menu.solutions.name}</div>
                    <div className="links__sections">
                        <div className="links__sections__section">
                            <div className="section__title">{menu.industries.name}</div>
                            <ul>
                                {sectors.map((sector, index) => (
                                    <li key={index}>
                                        <Link to={`${sectorUrl}/${sector.node.url}`}>
                                            {sector.node.tag}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="links__sections__section">
                            <div className="section__title">{menu.use_cases.name}</div>
                            <ul>
                                {useCases.map((useCase, index) => (
                                    <li key={index}>
                                        <Link to={`${useCasesUrl}/${useCase.node.url}`}>
                                            {useCase.node.footer_name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer__nav__links">
                    <div className="links__title">{menu.about_tecalis.name}</div>
                    <ul>
                        <li>
                            <Link to={menu.about_us.url}>{menu.about_us.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.news.url}>{menu.news.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.contact.url}>{menu.contact.name}</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer__nav__links">
                    <div className="links__title">{menu.resources.name}</div>
                    <ul>
                        <li>
                            <Link to={menu.blog.url}>{menu.blog.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.knowledge_center.url}>{menu.knowledge_center.name}</Link>
                        </li>
                        <li>
                            <Link to={menu.time_stamp_policies.url}>{menu.time_stamp_policies.name}</Link>
                        </li>
                    </ul>
                </div>
                <div className="footer__nav__links">
                    <div className="links__title">{menu.partners.name}</div>
                    <ul>
                        <li>
                            <Link to={menu.partners.url}>{menu.partners.name}</Link>
                        </li>
                        <li>
                            <a href="https://account.tecalis.com/">{menu.account.name}</a>
                        </li>
                    </ul>
                </div>
            </div>}
            <div className="footer__social">
                <div className="footer__social__links">
                    <span>© {new Date().getFullYear()} Copyright</span>
                    <Link to={menu.terms_use.url}>{menu.terms_use.name}</Link>
                    <Link to={menu.privacy.url}>{menu.privacy.name}</Link>
                    <Link to={menu.terms_conditions.url}>{menu.terms_conditions.name}</Link>
                    <Link to={menu.public_financing.url}>{menu.public_financing.name}</Link>
                </div>
                <div className="footer__social__apps">
                    <a className="apps__app" href="https://www.facebook.com/tecalis" target="_blank" rel="me">
                        <i className="icon-facebook"></i>
                    </a>
                    <a className="apps__app" href="https://twitter.com/tecalis" target="_blank" rel="me">
                        <i className="icon-x"></i>
                    </a>
                    <a className="apps__app" href="https://www.linkedin.com/company/tecalis" target="_blank" rel="me">
                        <i className="icon-linkedin"></i>
                    </a>
                    <a className="apps__app" href="https://www.instagram.com/tecalis" target="_blank" rel="me">
                        <i className="icon-instagram"></i>
                    </a>
                    <a className="apps__app" href="https://www.youtube.com/c/Tecalis" target="_blank" rel="me">
                        <i className="icon-youtube"></i>
                    </a>
                </div>
            </div>
        </div>
    </footer>
}

export default Footer
